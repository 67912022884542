// Gravity Forms Post Render 
jQuery(document).bind('gform_post_render', function(event, form_id, current_page) {

	if (form_id === 99999) {    	

    	

	}
 
});

if (jQuery('body').hasClass('page-id-46')) {

	jQuery('.bio-visible').click(function() {

		var boardMemberName = jQuery(this).find('p strong').text();

		var boardMemberPhoto = jQuery(this).find('img').attr('src');

		jQuery('#input_1_6').val(boardMemberName);

		jQuery('#board-member').text(boardMemberName);

		jQuery('#board-member-photo').html('<img src="' + boardMemberPhoto + '" alt="' + boardMemberName + '" />');
  
	});

}