function setFullScreenBackgroundHeight() {

	// check if full screen background container exists
	if (jQuery('#bla-full-screen-background').length) {

		// set variable to height of window
		var windowHeight = jQuery(window).outerHeight();
	
		// set variable to height of the header
		var headerHeight = jQuery('#site-header').outerHeight();
	
		// set variable to height of the first content section
		var firstContentSectionHeight = jQuery('#homepage-sections-wrapper section:first').outerHeight();
	
		// set variable to height of the header
		// and the first content section combined
		var headerPlusFirstSection = headerHeight + firstContentSectionHeight;
	
		// set variable to the difference between the window height
		// and the header height
		var firstContentSectionMinHeight = windowHeight - headerHeight;

		// if the header height plus the first section height
		// combined is larger than the window height
		if (headerPlusFirstSection > windowHeight) {

			// set the full screen background container height
			// to the height of the first section and the header
			jQuery('#bla-full-screen-background').css({

				'height':headerPlusFirstSection,

			});

		} else {

			// set the full screen background height 
			// to the height of the window
			jQuery('#bla-full-screen-background').css({

				'height':windowHeight,

			});

			// set the first section height to the height
			// of the window minus the header so that it 
			//fills the full screen background
			jQuery('#homepage-sections-wrapper section:first').css({

			'min-height':firstContentSectionMinHeight,

		});

		}

	}

}

jQuery(document).ready(function() {
	
	// run the function at the top of this
	// page after the document is ready
	setFullScreenBackgroundHeight();
	
	// debounced resize, fires once after all resizing stops
	jQuery(window).smartresize(function() {
		
		// if the window is resized run the function
		// at the top of this page after
		setFullScreenBackgroundHeight();

	});

	// add the Foundation off-canvas trigger attribute to the menu item
	//jQuery('#').attr('data-toggle', 'offCanvas');

});