jQuery('a').click(function(event) {
  
	var dataScrollTo = jQuery(this).attr('data-scroll-to');

	if (typeof dataScrollTo !== typeof undefined && dataScrollTo !== false) {

		var href = jQuery(this).attr('href');

		jQuery('html, body').animate({
        	scrollTop: jQuery(href).offset().top - 15
    	}, 1500);

		event.preventDefault();

	}

});